<template>
  <div>
    <p-navigation-drawer />
    <p-app-bar />

    <v-main class="mx-lg-6 mt-4">
      <v-container :fluid="!$vuetify.breakpoint.xl">
        <router-view></router-view>
      </v-container>
    </v-main>

    <p-footer />
  </div>
</template>

<script>
import pAppBar from './components/app-bar.vue';
import pFooter from './components/footer.vue';
import pNavigationDrawer from './components/navigation-drawer.vue';

export default {
  name: 'promoter.layout',
  components: {
    pAppBar,
    pFooter,
    pNavigationDrawer,
  },
};
</script>
