<template>
  <v-app-bar app rounded :elevation="1" color="primary" class="mx-3 mx-lg-8 mt-4">
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      color="white"
      @click="$root.$emit('admin-drawer')"
    ></v-app-bar-nav-icon>

    <p-breadcrumbs />

    <v-spacer />

    <div v-if="$user">
      <v-list-item dense class="text-right pa-0">
        <v-list-item-content>
          <v-list-item-title class="white--text text-bold">
            {{ $user.companyName || $user.email }}
          </v-list-item-title>

          <v-list-item-subtitle class="white--text">
            {{ $user.role | capitalize }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar>
          <v-img v-if="$user.image" :src="$getProfile200Image($user._id, $user.image)" />
          <v-img v-if="!$user.image" src="@/assets/images/promotor.svg" />
        </v-list-item-avatar>
      </v-list-item>
    </div>
  </v-app-bar>
</template>

<script>
import pBreadcrumbs from './breadcrumbs.vue';

export default {
  components: {
    pBreadcrumbs,
  },
};
</script>
