<template>
  <div>
    <div class="hidden-xs-only">
      <div
        v-if="$route.meta && $route.meta.breadcrumbs && $route.meta.breadcrumbs.length"
        class="d-flex my-4 rounded light--text"
      >
        <div class="text-h6 my-auto ">{{ $route.meta.title }}</div>

        <v-divider class="mx-4 light" vertical />

        <router-link to="/promoter/dashboard" class="d-flex">
          <v-icon color="light">mdi-home-outline</v-icon>
        </router-link>

        <div class="my-auto mx-3">>></div>

        <v-breadcrumbs class="py-0 px-0 text-bold" :items="$route.meta.breadcrumbs" divider=">>"></v-breadcrumbs>
      </div>
    </div>

    <div v-if="title" class="hidden-sm-and-up text-h6 my-auto ">{{ title }}</div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  computed: {
    title() {
      return _.last(this.$route.meta?.breadcrumbs)?.text;
    },
  },
};
</script>
