<template>
  <v-navigation-drawer app v-model="drawer">
    <v-list-item>
      <v-list-item-icon class="cursor-pointer mr-4" @click="$next('/')">
        <tt-icon width="48" icon="logo" />
      </v-list-item-icon>

      <v-list-item-title class="primary--text text-bold ">
        TICKET TEXT
      </v-list-item-title>

      <v-spacer />

      <v-list-item-icon class="ma-auto hidden-lg-and-up" @click="$root.$emit('admin-drawer')">
        <v-icon color="primary">mdi-close</v-icon>
      </v-list-item-icon>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav>
      <template v-for="item in items">
        <v-list-item
          class="v-list-item-icon-center"
          active-class="primary "
          :dense="!item.divider"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mb-2" v-if="item.divider" :key="item.title + 'divider'"></v-divider>
      </template>
    </v-list>

    <template v-slot:append>
      <v-list-item @click="$logout()">
        <v-list-item-icon>
          <v-icon color="primary">mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-home-outline', link: '/admin/dashboard', divider: true },
        { title: 'Users', icon: 'mdi-account-outline', link: '/admin/users' },
        { title: 'Performers', icon: 'mdi-account-music-outline', link: '/admin/performers' },
        { title: 'Promoters', icon: 'mdi-account-edit-outline', link: '/admin/promoters' },
        { title: 'Events', icon: 'mdi-calendar', link: '/admin/events' },
        // { title: 'Event types', icon: 'mdi-calendar-edit', link: '/admin/event-type' },
        { title: 'Event category', icon: 'mdi-calendar-multiple', link: '/admin/event-category' },
        { title: 'Event sub category', icon: 'mdi-calendar-multiple-check', link: '/admin/event-sub-category' },
        { title: 'Suppliers', icon: 'mdi-cart-outline', link: '/admin/suppliers' },
        { title: 'Add Ons', icon: 'mdi-plus-outline', link: '/admin/add-ons' },
        { title: 'Event series', icon: 'mdi-calendar-month-outline', link: '/admin/event-series' },
        { title: 'Venues', icon: 'mdi-map-marker-outline', link: '/admin/venues' },
        { title: 'Subscriptions', icon: 'mdi-account-convert-outline', link: '/admin/subscription' },
        { title: 'Promo codes', icon: 'mdi-tag-outline', link: '/admin/promo-codes' },
        { title: 'Ticket category ', icon: 'mdi-ticket-confirmation-outline', link: '/admin/ticket-category' },
        { title: 'Third Party', icon: 'mdi-account-multiple', link: '/admin/third-party' },
        { title: 'Seating plans ', icon: 'mdi-floor-plan', link: '/admin/seating-plan' },
        { title: 'Seats io designer', icon: 'mdi-seat-outline', link: '/admin/seats-io-designer' },
        { title: 'Reports', icon: 'mdi-chart-line', link: '/admin/reports' },
        { title: 'Customer Order History', icon: 'mdi-sale', link: '/admin/sales-reports' },
        { title: 'Custom Widget', icon: 'mdi-widgets', link: '/admin/custom-widget' },
        { title: 'Survey', icon: 'mdi-book-information-variant', link: '/admin/survey' }

      ],
      drawer: this.$vuetify.breakpoint.lgAndUp,
    };
  },
  mounted() {
    this.$root.$on('admin-drawer', () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
